// 접속자수 상위지역 바차트 레전드
.title-labels {
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-bottom: 3rem;
  gap: 20px;
  .option-boll {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 0.75rem;
    &::after {
      width: 14px;
      height: 14px;
      border-radius: 20px;
      content: '';
      position: absolute;
      left: 13px;
    }
    &.first::after {
      background-color: #f94144;
    }
    &.second::after {
      background-color: #f3722c;
    }
    &.third::after {
      background-color: #f8961e;
    }
    &.fourth::after {
      background-color: #f9c74f;
    }
    &.etc::after {
      background-color: #90be6d;
    }
  }
  .oplabel-flex-full {
    display: flex;
    gap: 25px;
    align-items: center;
    .form-label {
      flex: 0 0 140px;
      margin: 0;
      font-size: 0.875rem;
      color: #fff;
    }
  }
}
