table {
  border-collapse: collapse;
}

table tr {
  border-bottom: 1px solid #e0e0e0;
}

.ap-data-table th {
  padding: 1rem 0.5rem;
}

.ap-data-table .border-right:not(:last-child) {
  border-right: 1px solid #e0e0e0;
}
/* table-common */

thead tr {
  background-color: rgba(48, 83, 153, 0.1);
}
/* thead */

tbody td {
  text-align: center;
  padding: 0.75rem;
}

tbody th {
  background-color: rgba(48, 83, 153, 0.1);
}

tbody .am-total,
.pm-total {
  background-color: rgba(251, 227, 142, 0.4);
}
/* tbody */
