// custom.scss

// ----------------------------------------------------------------------------
// ---------------------------------- layout ----------------------------------
// ----------------------------------------------------------------------------
.app-container {
  .app-contents {
    background-color: var(--bs-main-bg);
    overflow: hidden;
    aside {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 11;
      width: 200px;
      flex: 0 0 200px;
      background-color: var(--bs-primary);
      transition: 0.25s ease-in;
      .logo-box {
        margin: 0;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        .logo {
          display: block;
          width: 100%;
          height: 100%;
          background: url('../images/logo_white.png') no-repeat calc(50% - 4px)
            center;
          cursor: pointer;
        }
      }
      nav {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        margin-top: 1rem;
        .nav-item {
          .nav-link {
            padding: 0;
            flex-wrap: wrap;
            cursor: pointer;
            .accordion-button {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 1.25rem 1rem;
              font-weight: 500;
              color: var(--bs-primary-light);
              white-space: nowrap;
              transition: 0.25s ease-in-out;
              span {
                padding-left: 0.625rem;
                transition: 0.25s ease-in-out;
              }
              &.bg-none {
                &:after {
                  display: none;
                }
              }
              &:after {
                transition: 0.25s ease-in-out;
              }
              &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                width: 3px;
                height: 100%;
                background: var(--bs-primary-light);
                opacity: 0;
              }
            }
            &.active {
              font-weight: 600;
              .accordion-button {
                color: var(--bs-white);
                background-color: var(--bs-primary-dark);
                position: relative;
                &:before {
                  opacity: 1;
                }
              }
            }
            ul {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: stretch;
              padding: 0.5rem 2rem 0.5rem 2rem;
              transition: 0.15s ease-in-out;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              li {
                a {
                  display: block;
                  padding: 0.25rem 0;
                  font-weight: 400;
                  color: var(--bs-primary-light);
                  &.active {
                    color: var(--bs-white);
                    font-weight: 500;
                  }
                  div {
                    font-size: medium;
                  }
                  p {
                    font-size: small;
                    margin-top: -4px;
                  }
                }
              }
            }
          }
        }
      }
    }
    main {
      padding-left: 200px;
      padding-top: 80px;
      flex: 1;
      height: 100vh;
      overflow-y: auto;
      .contents {
        width: 100%;
        padding: 2rem 2rem 2rem 2rem;
        article {
          padding: 1rem;
          border-radius: 0.25rem;
          background: var(--bs-white);
          box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
          &.scrollbar {
            overflow: auto;
          }
          &.mx-ht {
            height: 400px;
          }
          &.mi-ht {
            height: 400px;
            overflow: auto;
          }
          &.mi-wd {
            min-width: 1500px;
          }
        }
      }
    }
  }
}

// ----------------------------------------------------------------------------
// ------------------------------- custom-class -------------------------------
// ----------------------------------------------------------------------------
// flex
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  & > * {
    min-width: 1px;
  }
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > * {
    min-width: 1px;
  }
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > * {
    min-width: 1px;
  }
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > * {
    min-width: 1px;
  }
}
.flex-auto {
  flex-basis: auto !important;
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
}

// font-size
.fs-10 {
  font-size: var(--fs-10) !important;
}
.fs-11 {
  font-size: var(--fs-11) !important;
}
.fs-12 {
  font-size: var(--fs-12) !important;
}
.fs-14 {
  font-size: var(--fs-14) !important;
}
.fs-16 {
  font-size: var(--fs-16) !important;
}
.fs-18 {
  font-size: var(--fs-18) !important;
}
.fs-20 {
  font-size: var(--fs-20) !important;
}
.fs-24 {
  font-size: var(--fs-24) !important;
}
.fs-28 {
  font-size: var(--fs-28) !important;
}
.fs-32 {
  font-size: var(--fs-32) !important;
}
.fs-36 {
  font-size: var(--fs-36) !important;
}
.fs-48 {
  font-size: var(--fs-48) !important;
}
.fs-64 {
  font-size: var(--fs-64) !important;
}

// font-weight
.fw-100 {
  font-weight: 100 !important;
}
.fw-200 {
  font-weight: 200 !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}

// title
.title-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--bs-gray-500);
  h3 {
    font-size: var(--fs-16);
    margin: 0;
  }
  p {
    margin: 0 0 0 1rem;
    font-weight: 400;
    color: var(--bs-gray);
  }
}

// btn
.btn-area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
  & > * {
    margin: 0 0.25rem;
  }
  &.column {
    flex-wrap: wrap;
    & > * {
      margin: 0.125rem 0;
    }
  }
  &.justify-btn-side {
    justify-content: space-between;
  }
  &.justify-btn-end {
    justify-content: flex-end;
  }
  .flex-gap {
    display: flex;
    gap: 15px;
  }
}

// ellipsis
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
  width: inherit;
  width: 100%;
}
.word-break {
  word-break: break-all;
}
.ellipsis-line {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  &.line-2 {
    -webkit-line-clamp: 2 !important;
  }
  &.line-4 {
    -webkit-line-clamp: 4 !important;
  }
}

// form-group
form {
  .form-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1.5rem;
    &:first-of-type {
      margin-top: 0;
    }
    .form-label {
      margin: 0;
      flex: 0 0 100px;
      padding: 0;
      color: var(--bs-gray);
      font-size: var(--fs-18);
      font-weight: 400;
      & + * {
        flex-grow: 1;
        font-weight: 500;
        color: var(--bs-dark);
        word-break: break-all;
      }
    }
  }
  &.align-start {
    .form-group {
      align-items: flex-start;
    }
  }
  &.thick {
    .form-group {
      .form-label {
        padding: 0.712rem 0;
      }
    }
  }
  &.column {
    .form-group {
      flex-direction: column;
      align-items: stretch;
      .form-label {
        flex: 0 0 auto;
        padding-bottom: 0.5rem;
      }
    }
  }
}

// dl-box
.dl-box {
  dl {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1.5rem;
    &:first-of-type {
      margin-top: 0;
    }
    dt {
      margin: 0;
      flex: 0 0 100px;
      padding: 0;
      color: var(--bs-gray);
      font-size: var(--fs-18);
      font-weight: 400;
    }
    dd {
      flex-grow: 1;
      font-weight: 500;
      color: var(--bs-dark);
      word-break: break-all;
    }
  }
  &.align-start {
    dl {
      align-items: flex-start;
    }
  }
  &.thick {
    dl {
      dt {
        padding: 0.712rem 0;
      }
    }
  }
  &.column {
    dl {
      flex-direction: column;
      align-items: stretch;
      dt {
        flex: 0 0 auto;
        padding-bottom: 0.5rem;
      }
    }
  }
  &.row {
    dl {
      margin: 0;
    }
  }
  &.line {
    flex-direction: row;
    dl {
      display: inline-flex;
      margin: 0;
      padding: 0 0.5rem;
      position: relative;
      &:before {
        content: '';
        display: block;
        width: 1px;
        height: 12px;
        position: absolute;
        top: calc(50% - 6px);
        left: 0;
        background: var(--bs-border);
      }
      &:first-of-type {
        padding-left: 0;
        &:before {
          display: none;
        }
      }
    }
  }
}

// gray-box
.gray-box {
  padding: 0.5rem 1rem;
  background: var(--bs-blue-bg);
  border: 1px solid var(--bs-border);
  color: var(--bs-gray-dark);
  font-size: var(--fs-12);
}

// img-box
.img-box {
  background-color: var(--bs-ts);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

// shadow-box
.shadow-box {
  padding: 1rem;
  border-radius: 0.25rem;
  background: var(--bs-white);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
}

// no data
.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: var(--fs-14);
  font-weight: 500;
  text-align: center;
  color: var(--bs-secondary);
  padding: 1rem 0;
  margin: 0;
}
td.no-data {
  min-height: auto;
  padding: 2rem 0;
  display: table-cell;
  text-align: center !important;
}

// ----------------------------------------------------------------------------
// --------------------------------- library ----------------------------------
// ----------------------------------------------------------------------------
// swiper
.swiper-wrap {
  position: relative;
  width: 100%;
  .swiper-box {
    height: 100%;
    .swiper-slide {
      width: auto;
    }
  }
}
.swiper-navigation {
  z-index: 2;
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: calc(50% - 20px);
    margin-top: 0;
    width: 40px;
    height: 40px;
    --swiper-navigation-size: 20px;
    --swiper-navigation-color: white;
  }
}

// simplebar
[data-simplebar] {
  overflow-x: hidden;
}
.simplebar-content {
  padding-right: 1rem !important;
}
.simplebar-track.simplebar-vertical {
  width: 6px !important;
  background: var(--bs-blue-bg);
  opacity: 1;
}
.simplebar-scrollbar:before {
  background: var(--bs-gray-blue);
  left: 0;
  right: 0;
}
.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
}

// infinite-scroll
.infinite-scroll-component {
  overflow: inherit !important;
  .infinite-scroll-loading {
    padding: 2rem 0;
    color: var(--bs-gray);
    font-size: 1rem;
    text-align: center;
  }
}

// react-datepicker
.datepicker-wrap {
  flex: 0 0 auto !important;
  display: flex;
  align-items: center;
  max-width: 800px;
  & > * {
    min-width: 1px;
    margin: 0 0 0 0.5rem;
    &:first-child {
      margin: 0;
    }
  }
  & > span {
    display: block;
    flex-shrink: 0;
  }
  &.mw-100 {
    flex: 1 1 100% !important;
    & > * {
      max-width: inherit;
      .form-control {
        max-width: inherit;
      }
    }
  }
}
// react-datepicker calendar
.react-datepicker-popper[data-placement^='bottom'] {
  padding-top: 0.5rem;
  z-index: 5;
}
.react-datepicker__tab-loop {
  margin: 0 !important;
  width: 0 !important;
  min-width: 0 !important;
}
.react-datepicker.custom-calendar {
  // background: yellow;
  font-size: var(--fs-12);
  color: var(--bs-dark);
  border-radius: 0;
  border: 1px solid var(--bs-border);
  overflow: hidden;
  // 화살표
  .react-datepicker__navigation {
    top: 1rem;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: center;
    span {
      display: none;
    }
    &.react-datepicker__navigation--previous {
      left: calc(50% - 80px);
      background-image: url('../images/icon/calendar_prev.png');
    }
    &.react-datepicker__navigation--next {
      right: calc(50% - 80px);
      background-image: url('../images/icon/calendar_next.png');
    }
  }
  // 달력
  .react-datepicker__month-container {
    // 상단
    .react-datepicker__header {
      background: transparent;
      padding: 1rem 1rem 0;
      border: 0;
    }
    .react-datepicker__current-month {
      padding: 0 1rem;
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      font-size: var(--fs-14);
      line-height: 24px;
      font-weight: 700;
      color: var(--bs-dark);
      text-align: center;
    }
    .react-datepicker__day-names {
      padding: 0.5rem 0 0.25rem;
      .react-datepicker__day-name {
        color: var(--bs-gray);
        font-size: var(--fs-11);
        font-weight: 600;
      }
    }
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem;
      margin: 0.15rem 0.25rem;
    }
    // 하단
    .react-datepicker__day,
    .react-datepicker__time-name {
      color: var(--bs-dark);
      font-size: var(--fs-12);
      font-weight: 800;
    }
    .react-datepicker__month {
      margin: 0 1rem 1rem;
      .react-datepicker__day--outside-month,
      .react-datepicker__day--disabled,
      .react-datepicker__month-text--disabled,
      .react-datepicker__quarter-text--disabled,
      .react-datepicker__year-text--disabled {
        color: var(--bs-gray);
        font-weight: 800;
      }
      .react-datepicker__day--selected,
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--selected,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--selected,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--selected,
      .react-datepicker__year-text--in-selecting-range,
      .react-datepicker__year-text--in-range {
        background: var(--bs-primary);
        color: var(--bs-white);
      }
    }
  }
  // 시간
  .react-datepicker__time-container {
    min-width: 170px;
    .react-datepicker__header--time {
      padding: 1rem;
      border-bottom: 1px solid var(--bs-dark);
      display: none;
      .react-datepicker-time__header {
        font-size: var(--fs-16);
      }
    }
    .react-datepicker__time {
      .react-datepicker__time-box {
        width: 100%;
        ul.react-datepicker__time-list {
          li.react-datepicker__time-list-item {
            padding: 0.5rem;
            font-size: var(--fs-16);
            height: auto;
            text-align: left;
            &.time-list-item--removed {
              display: none;
            }
            &.react-datepicker__time-list-item--disabled {
              color: var(--bs-gray);
            }
            &.react-datepicker__time-list-item--selected {
              background-color: var(--bs-primary);
              color: var(--bs-white);
            }
          }
        }
      }
    }
  }
  .react-datepicker__input-time-container {
    width: 100%;
    margin: 0;
    float: inherit;
    .react-datepicker-time__caption {
      display: none;
    }
    .react-datepicker-time__input-container {
      display: block;
      div.react-datepicker-time__input {
        margin: 0;
        width: 100%;
        display: block;
        padding: 1rem;
        input.react-datepicker-time__input {
          margin: 0;
          width: 100%;
          padding: 0.6rem 0.8rem;
          line-height: 1.5;
          border-radius: 8px;
          border: 1px solid var(--bs-border);
          font-size: 1rem;
          font-weight: 500;
          color: var(--bs-dark);
          position: relative;
          &::-webkit-calendar-picker-indicator {
            background: green;
            position: absolute;
            left: -10px;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
          }
        }
      }
    }
  }
}

// chatbot
.react-chatbot-kit-chat-container {
  position: absolute;
  bottom: 2.8rem;
  right: 2.8rem;
  width: 400px;
  border-radius: 0.25rem;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  z-index: 3;
  .react-chatbot-kit-chat-inner-container {
    height: 560px;
    // header
    .react-chatbot-kit-chat-header {
      justify-content: space-between;
      align-items: center;
      padding: 0 1.5rem;
      height: 60px;
      background: var(--bs-white);
      border-bottom: 1px solid var(--bs-border);
    }
    // msg container
    .react-chatbot-kit-chat-message-container {
      height: 438px;
      padding: 1.5rem;
      .react-chatbot-kit-chat-bot-message,
      .react-chatbot-kit-user-chat-message {
        border-radius: 1rem;
        margin: 0;
        width: auto;
        max-width: 240px;
      }
      // chatbot msg
      .react-chatbot-kit-chat-bot-message-container {
        display: block;
        margin: 0.5rem 0;
        .react-chatbot-kit-chat-bot-message {
          background: var(--bs-blue-bg-dark);
          color: var(--bs-dark);
          margin: 0.5rem 0;
        }
      }
      // user msg
      .react-chatbot-kit-user-chat-message-container {
        margin: 0.5rem 0;
        .react-chatbot-kit-user-chat-message {
          background: var(--bs-primary);
          color: var(--bs-white);
        }
      }
    }
    // send form
    .react-chatbot-kit-chat-input-container {
      background: var(--bs-white);
      .react-chatbot-kit-chat-input-form {
        align-items: center;
        height: 80px;
        padding: 0 1.5rem;
        border-top: 1px solid var(--bs-border);
        .react-chatbot-kit-chat-input {
          background: var(--bs-blue-bg);
          height: 52px;
          border: 0;
          border-radius: 0.25rem;
          &::placeholder {
            color: var(--bs-gray);
          }
          &:focus,
          &:active {
            border: 0;
            outline: 0;
          }
        }
        .react-chatbot-kit-chat-btn-send {
          width: 52px;
          height: 52px;
          border-radius: 0.25rem;
          margin-left: 0.5rem;
          background: var(--bs-primary) url('../images/icon/send.png') no-repeat
            center;
          svg {
            display: none;
          }
        }
      }
    }
  }
  .react-chatbot-kit-user-avatar-container,
  .react-chatbot-kit-chat-bot-message-arrow,
  .react-chatbot-kit-user-chat-message-arrow,
  .react-chatbot-kit-chat-bot-avatar-letter {
    display: none;
  }
}

// swal
.swal2-popup {
  width: 400px;
  padding: 0;
}
.swal2-title {
  font-size: 1.25rem;
  color: var(--bs-dark);
}
.swal2-html-container {
  font-size: 1rem;
  line-height: 1.6;
  text-align: left;
  color: var(--bs-gray-dark);
  font-weight: 500;
  margin: 0;
  padding: 1.5rem;
}
.swal2-actions {
  width: 100%;
  justify-content: flex-end;
  margin: 0;
  padding: 0.5rem;
}
.swal2-styled {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  transition: 0.2s ease-in-out;
  &.swal2-confirm {
    background: var(--bs-primary);
    &:hover {
      background: var(--bs-primary-dark);
    }
  }
  &.swal2-cancel {
    background: var(--bs-ts) !important;
    border: 1px solid var(--bs-primary) !important;
    color: var(--bs-primary) !important;
    &:hover {
      background: var(--bs-primary-light) !important;
    }
  }
}

// ----------------------------------------------------------------------------
// --------------------------------- project ----------------------------------
// ----------------------------------------------------------------------------
.search-area {
  .form-group {
    margin-top: 0.5rem;
    .input-group
      > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
        .valid-feedback
      ):not(.invalid-tooltip):not(.invalid-feedback) {
      min-width: 60px;
    }
  }
}
.att-list {
  li {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:first-of-type {
      margin-top: 0;
    }
    .flex-start {
      .ratio {
        width: 80px;
      }
      p {
        margin-left: 1rem;
        font-weight: 400;
      }
    }
  }
}

// HOVER
@media (min-width: 1025px) {
  @media (hover: hover) {
  }
}

/* 0 ~ 1340 */
@media screen and (max-width: 1024px) {
  .app-container {
    .app-contents {
      main {
        padding-left: 60px;
      }
      aside {
        width: 60px;
        flex: 0 0 60px;
        .logo-box {
          .logo {
            background: url('../images/logo_symbol.png') no-repeat center;
          }
        }
        nav {
          .nav-item {
            .nav-link {
              .accordion-button {
                padding: 1.25rem;
                span {
                  opacity: 0;
                  width: 0;
                  padding-left: 0;
                }
                &:after {
                  opacity: 0;
                  width: 0;
                }
              }
              ul {
                opacity: 0;
                height: 0;
                padding: 0 0 0 3rem;
                transition: 0.35s ease-in-out;
              }
            }
          }
        }
        &:hover {
          width: 200px;
          flex: 0 0 200px;
          .logo-box {
            .logo {
              background: url('../images/logo_white.png') no-repeat
                calc(50% - 4px) center;
            }
          }
          nav {
            .nav-item {
              .nav-link {
                .accordion-button {
                  padding: 1.25rem 1rem;
                  span {
                    opacity: 1;
                    width: inherit;
                    padding-left: 0.625rem;
                  }
                  &:after {
                    opacity: 1;
                    width: 24px;
                  }
                }
                ul {
                  opacity: 1;
                  height: inherit;
                  padding: 0.5rem 0 0.5rem 3rem;
                  transition: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
