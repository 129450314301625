.modal {
  &.show {
    .modal-dialog {
      transform: none !important;
    }
  }
  &.add-scroll-area {
    margin-left: -8px;
  }
  .modal-dialog {
    max-width: 480px;
    margin: 3rem auto;
    &.modal-dialog-centered {
      min-height: calc(100% - 6rem);
      height: calc(100% - 6rem);
    }
    &.modal-sm {
      max-width: 400px;
    }
    &.modal-lg {
      max-width: 880px;
    }
    &.modal-xl {
      max-width: 1400px;
      min-width: 1280px;
    }
    &.modal-right {
      width: 300px;
      min-height: 100%;
      height: 100%;
      margin: 0;
      align-items: flex-start;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50px, 0);
      .modal-content {
        border-radius: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
      }
    }
    .modal-content {
      border: 0;
      border-radius: 0.25rem;
      .modal-header {
        border: 0;
        padding: 1rem;
        border-bottom: 1px solid var(--bs-border);
        .modal-title {
          font-weight: 700;
          font-size: var(--fs-18);
        }
        .btn-close {
          margin: 0 -0.75rem 0 auto;
          background-size: 0.85rem auto;
          padding: 0.75rem 1rem;
          opacity: 1 !important;
        }
      }
      .modal-body {
        padding: 1.5rem 1rem;
        font-weight: 500;
        [data-simplebar] {
          width: 100%;
        }
      }
      .modal-footer {
        padding-top: 0;
        border: 0;
        justify-content: center;
        .btn-lg {
          flex: 1;
        }
      }
    }
  }
}

// 유동 상황판 - 가입 지역 리스트
#modal-map-list {
  .modal-content {
    overflow: hidden;
    .modal-body {
      padding: 0;
      overflow-y: auto;
      .btn-group {
        width: 100%;
        display: flex;
        flex-direction: column;
        label.btn-list {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 1rem 0.875rem;
          border: 0;
          border-radius: 0;
          border-bottom: 1px solid var(--bs-border);
          h6 {
            margin: 0;
            font-weight: 800;
            color: var(--bs-dark);
          }
          strong {
            color: var(--bs-primary);
          }
        }
        input {
          &:checked {
            & + label.btn-list {
              h6 {
                color: var(--bs-primary) !important;
              }
            }
          }
        }
      }
    }
  }
}

// 홍보 스케쥴 관리
#modal-prm-schedule {
  .list-prmType {
    li {
      background: rgba(var(--bs-primary-rgb), 0.1);
      border-radius: 0.25rem;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      width: 120px;
      padding: 0.5rem;
      margin-right: 0.5rem;
      h6 {
        margin: 0;
        font-weight: 400;
        color: var(--bs-gray);
        font-size: var(--fs-14);
      }
      strong {
        font-size: var(--fs-14);
      }
    }
  }
  form {
    margin-top: 0.5rem;
    border-bottom: 1px solid var(--bs-border);
    .form-group {
      padding: 0.5rem 0;
      border-top: 1px solid var(--bs-border);
      margin: 0;
      .grid-line {
        display: grid;
        grid-template-columns: 100px 1fr;
        align-items: center;
        .grid-form {
          display: grid;
        }
      }
      .form-label {
        font-size: var(--fs-14);
        color: var(--bs-dark);
        font-weight: 600;
      }
      .datepicker-wrap.mw-100 {
        .react-datepicker-wrapper {
          width: inherit;
          .form-control {
            max-width: 200px !important;
          }
        }
      }
    }
  }
}
