.add-overflow-hidden {
  overflow: hidden !important;
}

.aside-ap-list td {
  font-size: var(--fs-12) !important;
}

.status-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.normal-circle {
  width: 1em;
  height: 1em;
  background-color: var(--normal-color);
  border-radius: 50%;
  margin-right: 0.2em;
}
.abnormal-circle {
  width: 1em;
  height: 1em;
  background-color: var(--abnormal-color);
  border-radius: 50%;
  margin-right: 0.2em;
}

.custom-marker-status-normal {
  display: grid;
  padding-top: 10px;
  padding-left: 3px;
  padding-right: 3px;
  box-sizing: border-box;
  position: relative;
  min-width: 93px;
  width: 100%;
  height: 52px;
  background: var(--normal-color);
  border-radius: 10px;
}

.custom-marker-status-normal::after {
  content: '';
  position: absolute;
  right: 50%;
  top: 70%;
  margin-top: 15px;
  border-top: 7px solid var(--normal-color);
  border-right: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid transparent;
}

.custom-marker-status-abnormal {
  display: grid;
  padding-top: 10px;
  padding-left: 3px;
  padding-right: 3px;
  box-sizing: border-box;
  position: relative;
  min-width: 93px;
  width: 100%;
  height: 52px;
  background: var(--abnormal-color);
  border-radius: 10px;
}

.custom-marker-status-abnormal::after {
  content: '';
  position: absolute;
  right: 50%;
  top: 70%;
  margin-top: 15px;
  border-top: 7px solid var(--abnormal-color);
  border-right: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid transparent;
}

.custom-marker-title-status {
  grid-column: 1 / 5;
  grid-row: 1 / 2;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: small;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ap-detail-item {
  display: flex;
  width: 100%;
}

.ap-detail-item .key {
  flex: 1;
}
.ap-detail-item .value {
  flex: 1;
  border-left: 1px solid var(--bs-border);
  padding-left: 8px;
}

.tr-ap-list-selected td {
  background-color: rgba(var(--bs-primary-rgb), 0.1) !important;
}

.dl-box-ap-status {
  dl {
    margin: 0;
    padding: 0.75rem 0 0;
    margin: 0.75rem 0 0;
    &:first-of-type {
      margin-top: 0;
      padding-top: 0;
      border-top: 0;
    }
    dt {
      margin: 0;
      flex: 0 0 100px;
      padding: 0;
      color: var(--bs-gray);
      font-size: var(--fs-12);
      font-weight: 400;
    }
    dd {
      flex-grow: 1;
      font-weight: 500;
      color: var(--bs-dark);
      word-break: break-all;
    }
  }
}

.dl-horizontal {
  display: flex;
}
.dl-horizontal dl {
  padding: 0;
  margin: 0;
  flex: 1; /* 동일한 너비로 3등분 */
}

.dl-box-ap-status dt,
.dl-box-ap-status dd {
  text-align: center;
}

.dl-box-ap-status dl:nth-of-type(1) dd {
  color: var(--total-color);
}
.dl-box-ap-status dl:nth-of-type(2) dd {
  border-left: 1px solid var(--bs-border);
  border-right: 1px solid var(--bs-border);
  color: var(--normal-color);
}

.dl-box-ap-status dl:nth-of-type(3) dd {
  color: var(--abnormal-color);
}

/*
section:last-child {
  height: calc(100% - 280px) !important;
}
  */

.ap-list-section {
  height: calc(100% - 280px);
}

.table-container {
  height: 100% !important;
  overflow-y: auto !important;
}

.table-fixed-header {
  position: sticky !important;
  top: 0 !important;
}

.table th {
  background: #eaedf5 !important;
}

.table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table .device-state-y {
  font-weight: bold;
}

.sensing-icon {
  color: var(--normal-color);
  font-size: 14px !important;
}

.sensing-icon-container {
  height: 20px;
  display: flex;
  width: 120px;
}

.sensing-icon-el {
  margin: auto;
  margin-left: 0px;
}

.sensing-icon-info {
  color: var(--bs-gray);
  font-size: var(--fs-12);
  font-weight: 400;
}
