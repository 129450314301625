// 로그인
#signin {
  // background: linear-gradient(
  //   256.38deg,
  //   #17c5fc 54.68%,
  //   #ebf1f7 112.19%,
  //   #d5e6f4 112.19%
  // );
  background: url(../images/signin-bg.png) no-repeat center / cover;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    .logo-area {
      margin-right: 6rem;
      .flex-column {
        flex-direction: column;
        p {
          color: var(--bs-white);
          margin-bottom: 50px;
          font-size: var(--fs-32);
          font-weight: 100;
        }
      }
      .title-area {
        margin: 4rem 0;
        padding: 0;
        border: 0;
        .wfs-title {
          font-family: var(--test);
        }
        h2 {
          font-size: 64px;
          color: var(--bs-white);
          text-align: center;
          font-family: var(--bs-body-font-family);
          font-family: var(--test);
          line-height: 1;
          span {
            color: #ffd972;
            font-family: var(--test);
          }
        }
      }
    }
    .form-box {
      min-width: 424px;
      padding: 1.5rem 1rem;
      border: 0;
      .title-area {
        margin-bottom: 4rem;
      }
      .btn-area.finds {
        span {
          width: 1px;
          height: 1rem;
          background: var(--bs-border);
        }
      }
    }
  }
}
.app-container {
  .app-contents {
    main.auth {
      padding: 0;
      background: var(--bs-white);
      display: flex;
      justify-content: center;
      align-items: center;
      .logo {
        display: block;
        margin: 0 auto 2rem;
        cursor: pointer;
      }
      .form-box {
        padding: 2.5rem 1.5rem;
        background: var(--bs-white);
        border-radius: 0.5rem;
        border: 1px solid var(--bs-border);
        .title-area {
          justify-content: center;
          border: 0;
          padding: 0;
          h3 {
            font-size: var(--fs-32);
            font-weight: 500;
          }
        }
      }
      .complete-wrap {
        p {
          font-size: var(--fs-20);
          font-weight: 500;
          text-align: center;
          strong {
            font-weight: 700;
            color: var(--bs-primary);
          }
        }
      }
    }
  }
}

// 에러
#errors {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--bs-gray-100);
}

// ---------------------------------------------------
// ---------------------------------------------------
// ---------------------------------------------------
// 유동상황판
#dashboard {
  .container-fluid {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;
  }
  // 지도영역
  .map-box {
    order: 2;
    flex: 1;
    height: 100%;
    position: relative;
    // 적용 후 삭제
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    color: #aaa;
    // 적용 후 삭제

    // 좌측 상단 breadcrumb
    .map-breadcrumb {
      position: absolute;
      top: 1.5rem;
      left: 1rem;
      padding: 0.25rem 1rem;
      background: var(--bs-white);
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      li {
        font-size: 1rem;
        font-weight: 700;
        color: var(--bs-dark);
        padding-left: 2.5rem;
        background: url('../images/icon/arrow_right.png') no-repeat 0.5rem
          center;
        &:first-of-type {
          padding-left: 0;
          background: none;
        }
      }
    }

    //map-mode-css
    .map-mode {
      display: flex;
      gap: 0.5rem;
      top: 4rem;
      left: 1rem;
      .btn-wide-view {
        font-size: 1rem;
        background-color: var(--bs-white);
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        gap: 0.3rem;
        padding: 0.3rem;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
        i {
          width: 18px;
          height: 18px;
          display: inline-flex;
          background-position: center;
          background-size: auto;
        }
        height: 45px;
      }
    }

    .map-permission {
      display: flex;
      top: 4rem;
      left: 7rem;
    }

    // 보행, 이동수단 css
    .direcion-choice {
      display: flex;
      align-items: center;
      font-size: 1.1rem;
      padding: 0.75rem 0.5rem;
      gap: 0.8rem;
      height: 45px;
      background-color: #fff;
      border-radius: 0.8rem;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
      border: 1px solid var(--bs-border);
      margin-bottom: 0;
      .direcion-span {
        display: flex;
        gap: 0.3rem;
      }
      .form-label {
        margin-bottom: 0;
      }
    }

    // 23.07.19 추가 css
    .direcion-test.direction-container {
      background-color: rgba(0, 16, 47, 0.8);
      label {
        color: #fff;
        font-family: var(--bs-body-font-family);
      }
    }

    // 우측 상단 고정영역
    .map-fix-right {
      position: absolute;
      top: 1.5rem;
      right: 1rem;
      // 가입지역, 미가입지역
      .map-help {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.25rem 1rem;
        background: var(--bs-white);
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        span {
          font-size: 1rem;
          color: var(--bs-dark);
          padding-left: 1.5rem;
          margin-left: 1.5rem;
          position: relative;
          font-weight: 700;
          &:first-of-type {
            margin: 0;
            &:before {
              background: var(--bs-info);
            }
          }
          &:before {
            content: '';
            display: block;
            position: absolute;
            top: calc(50% - 0.5rem);
            left: 0;
            width: 1rem;
            height: 1rem;
            border-radius: 1rem;
            margin-right: 0.25rem;
            // background: var(--bs-gray-500);
            background: #e95f67;
          }
        }
      }

      // 시각화
      .map-visual {
        .dropdown {
          .dropdown-toggle {
            background: var(--bs-white);
            color: var(--bs-dark);
            border: 1px solid var(--bs-border);
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
            height: 45px;
            min-width: auto;
            margin: 0px;
            &[aria-expanded='true'] {
              background: var(--bs-primary);
              color: var(--bs-white);
              border-color: var(--bs-primary);
            }
          }
        }
      }
    }

    .map-btn-left {
      position: absolute;
      top: 4rem;
    }

    .map-btn-right {
      position: absolute;
      display: flex;
      gap: 0.5rem;
      .btn-wide-view {
        font-size: 1rem;
        background-color: var(--bs-white);
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        gap: 0.3rem;
        padding: 0.3rem;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
        i {
          width: 20px;
          height: 20px;
          display: inline-flex;
          background-position: center;
          background-size: auto;
        }
      }
    }

    // 가입지역 버튼
    .btn-map-count {
      border-color: var(--bs-info);
      background: var(--bs-info);
      color: var(--bs-white);
      span {
        margin-left: 0.25rem;
      }
    }
    // 유닛 버튼
    .btn-map-unit {
      border-color: var(--bs-info);
      background: var(--bs-white);
      color: var(--bs-dark);
      border-radius: 0.25rem;
      padding: 0.25rem 0.5rem;
      margin: 0 2px;
      white-space: nowrap;
      span {
        transition: 0.15s ease-in-out;
        color: var(--bs-primary);
        padding-left: 0.5rem;
      }
      &:focus,
      &:active {
        border-color: var(--bs-primary-dark);
        background: var(--bs-primary-dark);
        color: var(--bs-white);
        span {
          color: var(--bs-white);
        }
      }
    }
    .btn-map-unit-select {
      border-color: var(--bs-primary-dark);
      background: var(--bs-primary-dark);
      color: var(--bs-white);
      border-radius: 0.25rem;
      padding: 0.25rem 0.5rem;
      margin: 0 2px;
      white-space: nowrap;
      span {
        transition: 0.15s ease-in-out;
        color: var(--bs-white);
        padding-left: 0.5rem;
      }
    }
  }
  // 좌측
  .map-aside {
    order: 1;
    flex: 0 0 350px;
    height: 100%;
    background: var(--bs-white);
    overflow-y: auto;
    section {
      padding: 1.5rem 1rem;
      border-top: 1px solid var(--bs-border);
      &:first-of-type {
        border-top: 0;
      }
      .chart-area {
        width: 100%;
        height: 200px;
      }
      strong {
        font-size: var(--fs-20);
        font-weight: 800;
        span {
          font-size: 1rem;
        }
      }
      // 전일 동시간대 증감
      .updown {
        display: flex;
        align-items: center;
        small {
          font-size: 1rem;
          margin: 0 0 0 0.5rem;
          display: inline-flex;
          align-items: center;
          color: var(--bs-secondary);
          &.up {
            color: var(--bs-danger);
          }
          &.dw {
            color: var(--bs-info);
          }
        }
      }
      // 구축센서
      .dl-box {
        dl {
          margin: 0;
          padding: 0.75rem 0 0;
          margin: 0.75rem 0 0;
          border-top: 1px solid rgba(var(--bs-border-rgb), 0.5);
          &:first-of-type {
            margin-top: 0;
            padding-top: 0;
            border-top: 0;
          }
          dt {
            font-size: var(--fs-12);
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

// 유동 상황판 - 유동인구 popOver
.unit-overlay {
  transform: translate(-110px, -190px);
  height: 192px;
  width: 320px;
  max-width: 320px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
  border: 0;
  .popover-arrow {
    display: none;
  }
  .popover-header {
    background: var(--bs-white);
    font-size: var(--fs-16);
    border: 0;
    span {
      color: var(--bs-gray-500);
    }
  }
  .chart-area {
    width: 100%;
    height: 120px;
  }
}

// ---------------------------------------------------
// ---------------------------------------------------
// ---------------------------------------------------
// 홍보 상세
.prm-detail {
  .dl-box {
    .grid-flex {
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-top: 1px solid var(--bs-gray-200);
      &.border-bottom {
        border-bottom: 1px solid var(--bs--gray-200);
      }
    }

    dl {
      margin: 0;
      padding: 0.75rem 0;
      border-top: 1px solid var(--bs-gray-200);
      &:first-of-type {
        border-top: 0;
      }
      dt {
        color: var(--bs-dark);
        font-weight: 600;
      }
      dt,
      dd {
        font-size: var(--fs-14);
      }
      dd.line {
        display: flex;
        align-items: center;
        span {
          display: block;
          padding-left: 0.5rem;
          margin-left: 0.5rem;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: calc(50% - 6px);
            display: block;
            width: 1px;
            height: 12px;
            background: var(--bs-gray-400);
          }
          &:first-of-type {
            padding-left: 0;
            margin-left: 0;
            &::before {
              display: none;
            }
          }
        }
      }
    }
    .flex-line {
      display: flex;
      align-items: center;
    }
    .map-box {
      width: 100%;
      height: 400px;
      border: 1px solid var(--bs-gray-200);
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-gray-100);
      .btn-map-unit-state {
        width: 24px;
        height: 24px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        border: 0;
        background: url('../images/icon/wifi.png') no-repeat center;
      }
    }
    .grid-flex {
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-top: 1px solid var(--bs-gray-200);
    }
  }
  .flex-gap {
    display: flex;
    gap: 20px;
  }
}

// 홍보 상세 - 행정동 사업 정보 - 지도 popOver
.unit-overlay-state {
  width: 160px;
  max-width: 160px;
  border: 1px solid var(--bs-primary);
  padding: 0.5rem;
  .popover-arrow {
    display: none;
  }
  .popover-header {
    background: var(--bs-white);
    font-size: var(--fs-14);
    border: 0;
    padding: 0 0 0.5rem;
    margin: 0 0 0.5rem;
    border-bottom: 1px solid var(--bs-gray-200);
  }
  .popover-body {
    padding: 0;
    .dl-box {
      dl {
        margin: 0;
        dt,
        dd {
          font-size: var(--fs-12);
        }
        dt {
          flex: 0 0 auto;
        }
        dd {
          margin-left: 0.25rem;
        }
      }
    }
  }
}

// 홍보 상세 - 행정동 사업 정보 - 지도 popOver
.custom-unit-overlay-state {
  width: 160px;
  max-width: 160px;
  border: 1px solid var(--bs-primary);
  padding: 0.5rem;
  .popover-arrow {
    display: none;
  }
  .popover-header {
    background: var(--bs-white);
    font-size: var(--fs-14);
    border: 0;
    padding: 0 0 0.5rem;
    margin: 0 0 0.5rem;
    border-bottom: 1px solid var(--bs-gray-200);
  }
  .popover-body {
    padding: 0;
    .dl-box {
      dl {
        padding: 0;
        border-top: 0;
        margin: 0;
        dt,
        dd {
          flex-grow: 1;
          font-weight: 500;
          color: var(--bs-dark);
          word-break: break-all;
          font-size: var(--fs-12);
        }
        dt {
          margin: 0;
          padding: 0;
          color: var(--bs-gray);
          font-size: var(--fs-12);
          font-weight: 400;
          flex: 0 0 auto;
        }
        dd {
          margin-left: 0.25rem;
        }
      }
    }
  }
}

//홍보 신규등록
#prm-request-register {
  .top-selectbox {
    max-width: 250px;
    margin-bottom: 1rem;
  }
  .grid-box {
    display: grid;
    grid-template-columns: 400px 1fr;
    gap: 30px;

    // 프로모션 상세
    .prm-request-detail {
      overflow: hidden;
      border: 1px solid #e0e0e0;
      height: 1000px;
      .prm-detail-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        background: var(--bs-white);
        z-index: 10;
        padding: 0 1rem;
        h3 {
          margin: 0;
          font-size: 1.25rem;
          color: var(--bs-gray);
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: normal;
          overflow: hidden;
          width: inherit;
          width: 80%;
          text-align: center;
        }
      }
      .img-top {
        .img-box {
          border: 1px solid #bebebe;
        }
      }
      .swiper-box {
        .swiper-slide {
          border: 1px solid #bebebe;
        }
      }
      .info {
        padding: 0.75rem 0 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 0 1 auto;
        max-height: 100%;
        overflow: auto;
        .title {
          padding: 0 1rem;

          .flex-between {
            padding-bottom: 0.5rem;
            border-bottom: 1px solid var(--bs-dark);
          }
          h6 {
            color: var(--bs-gray);
            font-weight: 800;
            margin: 0;
          }
          .badge {
            padding: 0.25rem 2rem;
            border: 1px solid var(--bs-secondary);
            background: #fffde9 !important;
            color: var(--bs-dark);
            font-size: 1rem;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
          }
        }
        .dl-box {
          padding: 0 1rem;
          dl {
            margin: 0;
            padding: 0.4rem 0;
            border-top: 1px solid var(--bs-border);
            &:first-of-type {
              border-top: 0;
            }
            .scrollbody {
              overflow-y: scroll;
              height: 400px;
            }
            dt {
              flex: 0 0 24px;
              display: inline-flex;
              justify-content: flex-start;
              align-items: center;
              i {
                margin-left: -0.1rem;
              }
            }
          }
        }
      }
    }
    .prm-request-input {
      .form-group {
        border-bottom: 1px solid #e0e0e0;
        padding: 1rem 0px;
        margin: 0;
        &.align-start {
          align-items: flex-start;
        }
        .form-label {
          flex: 0 0 110px;
        }
        .flex-gap {
          display: flex;
          gap: 15px;
        }
        .position-rel {
          position: relative;
        }
        .cancel-position {
          position: absolute;
          left: -40px;
          top: 10px;
        }
        .icon-btn {
          min-width: 0;
          width: 0;
        }
        .attatch-list {
          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
            .btn-icon {
              color: var(--bs-gray);
            }
            .ratio {
              width: 86px;
              margin: 0 1rem;
            }
            .flex-start {
              flex: 1;
              p {
                text-overflow: ellipsis;
                white-space: nowrap;
                word-wrap: normal;
                overflow: hidden;
                width: inherit;
                width: 80%;
              }
            }
          }
        }
      }
    }
  }
}
//홍보신규등록 모달
#business-modal {
  max-width: 900px;
  height: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  .modal-body {
    padding: 1rem;
    .dl-box {
      dl {
        margin: 0;
        padding: 0.75rem 0;
        border-top: 1px solid var(--bs-gray-200);
        &:first-of-type {
          border-top: 0;
        }
        dt {
          color: var(--bs-dark);
          font-weight: 600;
        }
        dt,
        dd {
          font-size: var(--fs-14);
        }
      }
      .row {
        --bs-gutter-x: 0;
        border-top: 1px solid var(--bs-gray-200);
        dl {
          border-top: 0;
        }
      }
      .map-box {
        width: 100%;
        height: 400px;
        border: 1px solid var(--bs-gray-200);
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--bs-gray-100);
        .btn-map-unit-state {
          width: 24px;
          height: 24px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          border: 0;
          background: url('../images/icon/wifi.png') no-repeat center;
        }
      }
    }
  }
  .form-group {
    border-bottom: 1px solid #e0e0e0;
    padding: 1rem 0px;
    margin: 0;
  }
}
@media (max-width: 1000px) {
  #business-modal {
    .dl-box {
      dl {
        overflow: auto;
      }
    }
  }
}
//-------------------------------------------------
//-------------------------------------------------
//소상공인 홍보 신규등록
#smbusiness-register {
  padding-left: 0;
  .top-selectbox {
    max-width: 250px;
    margin-bottom: 1rem;
  }
  .grid-box {
    display: grid;
    grid-template-columns: 360px 360px 1fr;
    gap: 10px;
    //메인이미지 상세
    .main-section {
      border: 1px solid #d9d9d9;
      height: 801px;
      .main-box {
        width: auto;
        height: 500px;
        margin-bottom: 0.25rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
      .sec-mainbox {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;

        background-color: #aaa;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
      .grid-box {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
        padding: 1rem 1rem 0.5rem 1rem;
        .ano-box {
          width: auto;
          height: 175px;
          background-color: #aaa;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
      }
    }
    // 프로모션 상세
    .prm-request-detail {
      overflow: hidden;
      border: 1px solid #e0e0e0;
      height: 801px;
      .prm-detail-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        background: var(--bs-white);
        z-index: 10;
        padding: 0 1rem;
        h3 {
          margin: 0;
          font-size: 1.25rem;
          color: var(--bs-gray);
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: normal;
          overflow: hidden;
          width: inherit;
          width: 80%;
          text-align: center;
        }
      }
      .img-top {
        .img-box {
          border: 1px solid #bebebe;
        }
      }
      .swiper-box {
        .swiper-slide {
          border: 1px solid #bebebe;
        }
      }
      .info {
        padding: 0.75rem 0 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 0 1 auto;
        max-height: 100%;
        overflow: auto;
        .title {
          padding: 0 1rem;

          .flex-between {
            padding-bottom: 0.5rem;
            border-bottom: 1px solid var(--bs-dark);
          }
          h6 {
            color: var(--bs-gray);
            font-weight: 800;
            margin: 0;
          }
          .badge {
            padding: 0.25rem 2rem;
            border: 1px solid var(--bs-secondary);
            background: #fffde9 !important;
            color: var(--bs-dark);
            font-size: 1rem;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
          }
        }
        .dl-box {
          padding: 0 1rem;
          dl {
            margin: 0;
            padding: 0.4rem 0;
            border-top: 1px solid var(--bs-border);
            &:first-of-type {
              border-top: 0;
            }
            .scrollbody {
              overflow-y: scroll;
              height: 310px;
            }
            dt {
              flex: 0 0 24px;
              display: inline-flex;
              justify-content: flex-start;
              align-items: center;
              i {
                margin-left: -0.1rem;
              }
            }
          }
        }
      }
    }
    .prm-request-input {
      .form-group {
        border-bottom: 1px solid #e0e0e0;
        padding: 1rem 0px;
        margin: 0;
        &.align-start {
          align-items: flex-start;
        }
        .form-label {
          flex: 0 0 110px;
        }
        .flex-gap {
          display: flex;
          gap: 15px;
        }
        .position-rel {
          position: relative;
        }
        .cancel-position {
          position: absolute;
          left: -40px;
          top: 10px;
        }
        .icon-btn {
          min-width: 0;
          width: 0;
        }
        .attatch-list {
          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
            .btn-icon {
              color: var(--bs-gray);
            }
            .ratio {
              width: 86px;
              margin: 0 1rem;
            }
            .flex-start {
              flex: 1;
              p {
                text-overflow: ellipsis;
                white-space: nowrap;
                word-wrap: normal;
                overflow: hidden;
                width: inherit;
                width: 80%;
              }
            }
          }
        }
      }
    }
  }
}
// ---------------------------------------------------
// ---------------------------------------------------
// ---------------------------------------------------
// 홍보 상황판
#prm-situation {
  .container-fluid {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;
  }
  // 지도영역
  .map-box {
    order: 2;
    flex: 1;
    height: 100%;
    position: relative;
    // 적용 후 삭제
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    color: #aaa;
    // 가입지역 버튼
    .btn-map-count {
      border-color: var(--bs-info);
      background: var(--bs-info);
      color: var(--bs-white);
      span {
        margin-left: 0.25rem;
      }
    }
  }
  // 좌측
  .map-aside {
    order: 1;
    flex: 0 0 350px;
    height: 100%;
    background: var(--bs-white);
    overflow-y: auto;
    position: relative;
    section {
      padding: 1.5rem 1rem;
      border-top: 1px solid var(--bs-border);
      &:first-of-type {
        border-top: 0;
      }
      .chart-area {
        width: 100%;
        height: 200px;
      }
      strong {
        font-size: var(--fs-20);
        font-weight: 800;
        span {
          font-size: 1rem;
        }
      }
    }
  }
  .pr-img {
    background-repeat: no-repeat;
    width: 100%;
    height: 174px;
    margin-bottom: 15px;
  }
  .pr-span {
    font-size: 12px;
    margin-left: 10px;
  }
  .pr-title {
    color: var(--bs-gray);
    display: flex;
    align-items: center;
  }
  .pr-box {
    .ellipsisbody {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .no-list {
    width: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    font-size: 1rem;
  }
  //모달 상세
  .prm-request-detail {
    overflow: hidden;
    .btn-close {
      margin-bottom: 0.5rem;
      position: absolute;
      z-index: 10;
      top: 27px;
      right: 19px;
    }
    .info {
      padding: 0.75rem 0 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex: 0 1 auto;
      max-height: 100%;
      overflow: auto;
      h5 {
        text-align: center;
      }
      .title {
        padding: 0;
        .flex-between {
          padding: 1rem 0.5rem;
          border-top: 1px solid var(--bs-dark);
          border-bottom: 1px solid var(--bs-dark);
        }
        h6 {
          color: var(--bs-gray);
          font-weight: 800;
          margin: 0;
        }
        .badge {
          padding: 0.25rem 2rem;
          border: 1px solid var(--bs-secondary);
          background: #fffde9 !important;
          color: var(--bs-dark);
          font-size: 1rem;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
        }
      }
      .dl-box {
        padding: 0 0.5rem;
        dl {
          margin: 0;
          padding: 0.4rem 0;
          &:first-of-type {
            border-top: 0;
          }
          //...효과 4줄
          .ellipsisbody {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          dt {
            flex: 0 0 30px;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            i {
              margin-left: -0.1rem;
            }
          }
        }
      }
    }
  }
}

// ---------------------------------------------
// ---------------------------------------------
// 홍보 스케쥴
#prm-schedule {
  .top-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    .list-prmType {
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
      max-width: 620px;
      li {
        background: var(--bs-white);
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 200px;
        padding: 0.75rem;

        &:nth-child(1) {
          border-bottom-left-radius: 0.25rem;
          border-top-left-radius: 0.25rem;
        }
        &:nth-child(3) {
          border-bottom-right-radius: 0.25rem;
          border-top-right-radius: 0.25rem;
        }
        h6 {
          margin: 0;
          font-weight: 400;
          color: var(--bs-gray);
          font-size: var(--fs-14);
        }
        strong {
          font-size: var(--fs-14);
        }
      }
    }
  }
  .grid-calender {
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 0.5rem;
  }
  .title-color {
    color: #161616;
    font-size: 14px;
    font-style: normal;
    text-align: right;
  }
  .time-color {
    color: #6f6f6f;
    font-size: 12px;
    font-weight: 400;
  }
  .border-ball {
    width: 6px;
    height: 6px;
    background-color: #305399;
  }
  .fc .fc-daygrid-day-top {
    display: grid;
    grid-template-columns: 1fr;
  }
  .fc .fc-daygrid-day-frame {
    min-height: 100%;
    height: 150px;
    position: relative;
  }
  .fc .fc-button-primary {
    border: none;
    background-color: #ffffff;
    color: black;
  }
  .fc .fc-toolbar {
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  .fc .fc-toolbar-title {
    font-size: 1.5em;
    margin: 0;
  }
  .caln-box {
    .list {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 1rem;
      &.Green::after {
        background-color: #00974e;
      }
      &.Orange::after {
        background-color: #ff8541;
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 9px;
        width: 6px;
        height: 6px;
        background-color: #305399;
        border-radius: 10px;
      }
      .b-word {
        word-break: break-all;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 150px;
        &:hover {
          text-decoration: underline;
          text-underline-offset: 5px;
        }
      }
    }
  }
  .seemore {
    margin-top: 1rem;
    color: #525252;
    font-weight: 500;
    font-size: 14px;
    cursor: default;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
      text-underline-offset: 5px;
    }
    // text-decoration: underline;
  }
}
//캘린더 모달
#calenderModal {
  max-width: 880px;
  .dl-box {
    overflow: auto;
    height: 400px;
    dl {
      margin: 0;
      padding: 0 0 0.75rem;
      margin: 0.75rem 0 0;
      border-bottom: 1px solid rgba(var(--bs-border-rgb), 0.5);
      &:first-of-type {
        margin-top: 0;
        padding-top: 0;
        border-top: 0;
      }
      dt {
        font-size: 18px;
        padding-bottom: 0.5rem;
        color: #161616;
        font-weight: bold;
      }
    }
    .dl-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .dl-baseline {
        align-self: baseline;
        .base-dt {
          background-color: #00974e;
          color: #ffffff;
          border-radius: 4px;
          font-size: 0.875rem;
          width: 60px;
          align-items: center;
          display: flex;
          justify-content: center;
          padding: 0;
          height: 20px;
          font-weight: 500;
          &.localgor {
            background-color: #305399;
          }
          &.smbusiness {
            background-color: #ff8541;
          }
        }
      }
      dd {
        color: #6f6f6f;
        font-size: 14px;
      }
    }
  }
}
#sysModal {
  max-width: 615px;
  .sys-area {
    .form-control.mw-280 {
      max-width: 100%;
    }
    .form-group {
      border-bottom: 1px solid #e0e0e0;
      padding: 1rem 0px;
      margin: 0;
      .input-group
        > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
          .valid-feedback
        ):not(.invalid-tooltip):not(.invalid-feedback) {
        min-width: 100px;
      }
      &.align-start {
        align-items: flex-start;
      }
      .form-label {
        flex: 0 0 110px;
      }
      .flex-gap {
        display: flex;
        gap: 15px;
      }
      .password-box {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
      }
      .align-label {
        display: flex;
        align-self: flex-start;
      }
      .position-rel {
        position: relative;
      }
      .cancel-position {
        position: absolute;
        left: -40px;
        top: 10px;
      }
      .icon-btn {
        min-width: 0;
        width: 0;
      }
      .attatch-list {
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;
          .btn-icon {
            color: var(--bs-gray);
          }
          .ratio {
            width: 86px;
            margin: 0 1rem;
          }
          .flex-start {
            flex: 1;
            p {
              text-overflow: ellipsis;
              white-space: nowrap;
              word-wrap: normal;
              overflow: hidden;
              width: inherit;
              width: 80%;
            }
          }
        }
      }
    }
  }
}
//메인이미지모달
#mainimagesModal {
  max-width: 384px;
  .main-section {
    .main-box {
      width: auto;
      height: 400px;
      background-color: #aaa;
      margin-bottom: 0.25rem;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .sec-mainbox {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      // border: 1px dashed #305399;
      margin-bottom: 1rem;
    }
    .grid-box {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
      .ano-box {
        width: auto;
        height: 180px;
        background-color: #aaa;
      }
    }
  }
}
//상세이미지모달
#detailimagesModal {
  .prm-request-detail {
    overflow: hidden;
    border: 1px solid #e0e0e0;
    height: 750px;
    .prm-detail-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      background: var(--bs-white);
      z-index: 10;
      padding: 0 1rem;
      h3 {
        margin: 0;
        font-size: 1.25rem;
        color: var(--bs-gray);
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
        overflow: hidden;
        width: inherit;
        width: 80%;
        text-align: center;
      }
    }
    .img-top {
      .img-box {
        border: 1px solid #bebebe;
      }
    }
    .swiper-box {
      .swiper-slide {
        border: 1px solid #bebebe;
      }
    }
    .info {
      padding: 0.75rem 0 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex: 0 1 auto;
      max-height: 100%;
      overflow: auto;
      .title {
        padding: 0 1rem;

        .flex-between {
          padding-bottom: 0.5rem;
          border-bottom: 1px solid var(--bs-dark);
        }
        h6 {
          color: var(--bs-gray);
          font-weight: 800;
          margin: 0;
        }
        .badge {
          padding: 0.25rem 2rem;
          border: 1px solid var(--bs-secondary);
          background: #fffde9 !important;
          color: var(--bs-dark);
          font-size: 1rem;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
        }
      }
      .dl-box {
        padding: 0 1rem;
        dl {
          margin: 0;
          padding: 0.4rem 0;
          border-top: 1px solid var(--bs-border);
          &:first-of-type {
            border-top: 0;
          }
          .scrollbody {
            overflow-y: scroll;
            height: 400px;
          }
          dt {
            flex: 0 0 24px;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            i {
              margin-left: -0.1rem;
            }
          }
        }
      }
    }
  }
}
//-----------------------
//-----------------------
//권한 관리
#sys-auth {
  .table td {
    padding: 1rem;
  }
}
#sys-code {
  .form-grid-f {
    display: flex;
    align-items: center;
    gap: 20px;
    .block-flex {
      display: block;
      margin-top: 0;
    }
  }
  .blcok-self {
    display: block;
    margin-top: 1rem;
  }
  .btn-area {
    margin: 1rem 0 0 0;
  }
  .code-table {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .back-color-tb {
    background-color: rgb(43 83 153 / 10%);
  }
  .table.text-start td {
    word-break: break-all;
  }
  .th-center {
    text-align: center;
  }
}
//------------------------
//------------------------
//홍보 요청 리스트
#prm-request-list {
  .signal {
    margin: auto;
    font-weight: bold;
    width: 65px;
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: #8c8c8c;
    background-color: rgb(202 202 202 / 30%);
    &.end {
      background-color: rgb(218 30 40 / 30%);
      color: #da1e28;
    }
    &.addon {
      background-color: rgb(36 161 72 / 30%);
      color: #24a148;
    }
    &.cancel {
      color: #eb6200;
      background-color: rgb(235 98 0 / 30%);
    }
  }
}
//------------------------
//------------------------
//홍보 요청 리스트 상세
#prm-request-detail {
  .flex-label {
    display: flex;
    align-items: center;
    gap: 10px;
    .signal {
      margin: auto;
      width: 65px;
      display: flex;
      height: 25px;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      border-radius: 4px;
      color: #8c8c8c;
      background-color: rgb(202 202 202 / 30%);
      &.end {
        background-color: rgb(218 30 40 / 30%);
        color: #da1e28;
      }
      &.addon {
        background-color: rgb(36 161 72 / 30%);
        color: #24a148;
      }
      &.cancel {
        color: #eb6200;
        background-color: rgb(235 98 0 / 30%);
      }
    }
  }
}
//------------------------
//------------------------
//홍보 리스트 상세
#prm-confirm-detail {
  .flex-label {
    display: flex;
    align-items: center;
    gap: 10px;
    .signal {
      margin: auto;
      width: 65px;
      display: flex;
      height: 25px;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      border-radius: 4px;
      color: #8c8c8c;
      background-color: rgb(202 202 202 / 30%);
      &.end {
        background-color: rgb(218 30 40 / 30%);
        color: #da1e28;
      }
      &.addon {
        background-color: rgb(36 161 72 / 30%);
        color: #24a148;
      }
      &.cancel {
        color: #eb6200;
        background-color: rgb(235 98 0 / 30%);
      }
    }
  }
  .linkdiv {
    .form-check {
      line-height: normal;
      min-height: 0;
    }
  }
}
//------------------------------
//-------------------------------
//홍보 리스트
#prm-confirm-list {
  .grid-group {
    display: grid;
    grid-template-columns: 530px 1fr;
    align-items: center;
  }
  .signal {
    margin: auto;
    width: 65px;
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 4px;
    color: #8c8c8c;
    background-color: rgb(202 202 202 / 30%);
    &.end {
      background-color: rgb(218 30 40 / 30%);
      color: #da1e28;
    }
    &.addon {
      background-color: rgb(36 161 72 / 30%);
      color: #24a148;
    }
    &.cancel {
      color: #eb6200;
      background-color: rgb(235 98 0 / 30%);
    }
  }
}

//-------------------------------
//-------------------------------
//쿠폰 관리
#prm-coupon-list {
  .pd-lr {
    padding: 0 0.2rem;
  }
  .flex-td {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
}
//-------------------------------
//-------------------------------
//쿠폰 상세
#prm-coupon-detail {
  .grid-div {
    display: grid;
    grid-template-columns: 400px 400px 1fr;
    gap: 15px;
    //메인이미지 상세
    .main-section {
      border: 1px solid #d9d9d9;
      height: 801px;
      .main-box {
        width: auto;
        height: 500px;
        margin-bottom: 0.25rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
        .grid-label {
          z-index: 100;
          position: absolute;
          bottom: 7px;
          right: 10px;
          height: 40px;
          border: 2px solid #203455;
          border-radius: 8px;
          height: 40px;
          .sale-text {
            background-color: #203455;
            color: #ffffff;
            text-align: center;
            font-size: 0.5rem;
            height: 100%;
            float: right;
            padding: 4px 0.5rem 0;
            line-height: 1.3;
          }
          .sale-won {
            background-color: #ffffff;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            text-align: center;
            font-size: 1rem;
            height: 100%;
            font-weight: bold;
            float: left;
            padding: 0 0.5rem;
            line-height: 36px;
            color: #203455;
            span {
              font-size: 0.75rem;
              font-weight: 500;
              margin-left: 2px;
            }
          }
        }
      }
      .sec-mainbox {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        position: relative;
        background-color: #aaa;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        .grid-label {
          z-index: 100;
          position: absolute;
          bottom: 7px;
          right: 10px;
          height: 40px;
          border: 2px solid #203455;
          border-radius: 8px;
          height: 40px;
          .sale-text {
            background-color: #203455;
            color: #ffffff;
            text-align: center;
            font-size: 0.5rem;
            height: 100%;
            float: right;
            padding: 4px 0.5rem 0;
            line-height: 1.3;
          }
          .sale-won {
            background-color: #ffffff;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            text-align: center;
            font-size: 1rem;
            height: 100%;
            font-weight: bold;
            float: left;
            padding: 0 0.5rem;
            line-height: 36px;
            color: #203455;
            span {
              font-size: 0.75rem;
              font-weight: 500;
              margin-left: 2px;
            }
          }
        }
      }
      .grid-box {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
        padding: 1rem 1rem 0.5rem 1rem;
        .ano-box {
          width: auto;
          height: 175px;
          position: relative;
          background-color: #aaa;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          .grid-label {
            z-index: 100;
            position: absolute;
            bottom: 7px;
            right: 10px;
            height: 40px;
            border: 2px solid #203455;
            border-radius: 8px;
            height: 40px;
            .sale-text {
              background-color: #203455;
              color: #ffffff;
              text-align: center;
              font-size: 0.5rem;
              height: 100%;
              float: right;
              padding: 0.2rem 0.2rem;
            }
            .sale-won {
              background-color: #ffffff;
              border-top-left-radius: 6px;
              border-bottom-left-radius: 6px;
              text-align: center;
              font-size: 1rem;
              height: 100%;
              font-weight: bold;
              float: left;
              padding: 0 0.2rem;
              line-height: 36px;
              color: #203455;
              span {
                font-size: 0.75rem;
                font-weight: 500;
                margin-left: 2px;
              }
            }
          }
        }
      }
    }
    .info {
      height: 801px;
      border: 1px solid #e0e0e0;
      background-color: #f9f9f9;
      .prm-detail-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        background: var(--bs-white);
        z-index: 10;
        padding: 0 1rem;
        h3 {
          margin: 0;
          font-size: 1.25rem;
          color: var(--bs-gray);
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: normal;
          overflow: hidden;
          width: inherit;
          width: 80%;
          text-align: center;
        }
      }
      .coupon-section {
        padding: 2rem 1rem;
        background-color: #f9f9f9;

        .coupon-text {
          font-size: 1rem;
          color: #6f6f6f;
          text-align: center;
          line-height: 1.6;
        }
        .coupon-box {
          width: 100%;
          height: 128px;
          border: 1px solid #e0e0e0;
          background-color: #ffffff;
          border-radius: 12px;
          padding: 1rem 1.5rem 1rem 1rem;
          position: relative;
          margin-bottom: 1rem;
          .won-top {
            background-color: #f9f9f9;
            width: 42px;
            height: 20px;
            border: 1px solid #e0e0e0;
            position: absolute;
            top: -1px;
            right: 66px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            border-top: 0;
            z-index: 10;
          }
          .won-bot {
            background-color: #f9f9f9;
            width: 42px;
            height: 20px;
            border: 1px solid #e0e0e0;
            position: absolute;
            bottom: -1px;
            right: 66px;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            border-bottom: 0;
            z-index: 10;
          }
          .coupon-title {
            p {
              font-size: 1rem;
            }
          }
          .grid-down {
            display: grid;
            grid-template-columns: 1fr 80px;
            align-items: center;
            margin-bottom: 0.5rem;
            .right-icons {
              justify-self: end;
            }
            p {
              font-size: 2.25rem;
              font-weight: bold;
            }
          }
          .flex-day {
            display: flex;
            align-items: center;
            gap: 10px;
            p {
              color: #6f6f6f;
              font-size: 1rem;
            }
          }
        }
      }
    }
    .prm-request-input {
      .form-group {
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 1rem;
        .check-div {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-left: 1rem;
        }
      }
    }
  }
}

//-----------------------------------------
//-----------------------------------------
//과금 관리
#billing-list {
  .list-prmType {
    li {
      background: rgba(var(--bs-primary-rgb), 0.1);
      border-radius: 0.25rem;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      width: 130px;
      padding: 0.5rem;
      margin-right: 0.5rem;
      h6 {
        margin: 0;
        font-weight: 400;
        color: var(--bs-gray);
        font-size: var(--fs-14);
      }
      strong {
        font-size: var(--fs-14);
      }
    }
  }
  .article-grid {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 20px;
  }
  .pd-lr {
    padding: 0 0.2rem;
  }
  .flex-td {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .b-day {
    .day-box {
      padding: 1rem 0.5rem;
      cursor: pointer;
      border-bottom: 1px solid #e0e0e0;
      p {
        font-weight: 500;
      }
      &:hover {
        background-color: rgb(48 83 153 / 8%);
        p {
          font-weight: bold;
        }
      }
    }
  }
  .mx-ht {
    table td,
    th {
      text-align: center;
    }
    .bd-ri {
      border-right: 1px solid #e0e0e0;
    }
    .bd-bt {
      border-bottom: 1px solid #e0e0e0;
    }
  }
  .prmtable {
    .btn-side-jus {
      display: flex;
      justify-content: space-between;
      h5 {
        margin-bottom: 0;
      }
    }
    .flex-title {
      margin-top: 1rem;
      .prm-flex {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 15px;
        .prm-title-color {
          font-weight: bold;
          padding-left: 5px;
        }
      }
    }
  }
}
//-------------------------------
//-------------------------------
//설문조사 등록
#prm-sur-detail {
  .result-title {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 1rem;
  }
  .grid-div {
    display: grid;
    grid-template-columns: 400px 1fr;
    gap: 15px;
    .survey {
      padding: 1rem;
      background-color: #d5e6f4;
      padding-top: 80px;
      position: relative;
      overflow: auto;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      .border-line {
        border-bottom: 10px solid #e8e8e8;
        margin-bottom: 1rem;
      }
      article {
        padding: 1rem;
        border-radius: 1rem;
        background: var(--bs-white);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      }
      .flex-form {
        display: flex;
        gap: 10px;
        margin-top: 1rem;
        .form-label {
          margin: 0;
          color: #4e4e4e;
          font-size: 1rem;
        }
      }
      header {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 1024px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        padding: 0 1rem;
        background-color: #203455;
        z-index: 9;
        h3 {
          margin: 0;
          font-size: 1.25rem;
          color: var(--bs-white);
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: normal;
          overflow: hidden;
          width: inherit;
          width: 80%;
          text-align: center;
        }
      }
      .btn-radius {
        border-radius: 0.5rem;
      }
    }
    .prm-request-input {
      .form-group {
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 1rem;
        .check-div {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        &.flex-check {
          display: flex;
          align-items: center;
          .flex-form {
            margin-top: 0;
          }
        }
      }
      .que-box {
        border: 1px solid #e0e0e0;
        padding: 1rem;
        border-radius: 8px;
        .btn-flex {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 10px;
        }
        .que-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          h6 {
            margin-bottom: 0;
          }
        }
        .flex-form {
          display: flex;
          gap: 10px;
          margin-top: 1rem;
          align-items: center;
          .form-label {
            margin: 0;
            color: #4e4e4e;
            font-size: 1rem;
          }
        }
      }
    }
  }
}
//----------------------------------
//----------------------------------
//설문조사 결과
#prm-sur-result {
  .result-title {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 1rem;
  }
  .result-box {
    height: 400px;
    border: 1px solid #e0e0e0;
    padding: 1rem;
    .grid-result {
      display: grid;
      grid-template-columns: 250px 1fr;
      gap: 50px;
      align-items: center;
      .scroll-option {
        overflow: auto;
        height: 250px;
        .form-flex {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-top: 1px solid #e0e0e0;
          padding: 1rem 0.5rem 1rem 2.5rem;
          margin-top: 0;
          position: relative;
          .option-boll {
            position: absolute;
            display: flex;
            align-items: center;
            margin-right: 1rem;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            left: 5px;
          }
          .oplabel-flex {
            display: flex;
            gap: 10px;
            align-items: center;
          }
          .opval-flex {
            display: flex;
            gap: 10px;
            align-items: center;
            width: 65px;
            justify-content: space-between;
          }
        }
      }
    }
  }
  //응답결과 없을때
  .noresult-box {
    height: 150px;
    border: 1px solid #e0e0e0;
    padding: 1rem;
    .grid-noresult {
      display: grid;
      grid-template-columns: 1fr;
      .noresult-div {
        text-align: center;
        p {
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }
  }
}
//----------------------------------
//----------------------------------
//상위지역 데이터
#datalab-topdata {
  h5 {
    margin-bottom: 0;
  }
  .title-datalab {
    border-bottom: 1px solid #8b8b8b;
    margin-bottom: 1rem;
    h5 {
      margin-bottom: 0.5rem;
      font-weight: bold;
    }
  }
  .form-flex {
    display: flex;
    align-items: center;
    gap: 50px;
    .form-group {
      margin-top: 0;
    }
  }
  .form-label {
    flex: 0 0 80px;
  }
  .btn-center {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
  .btn-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 1.5rem;
    .xlsx {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 21px;
      height: 21px;
    }
  }
  .td-right {
    text-align: right;
  }

  //유동인구 접속자수 상위지역 라벨
  .title-labels {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 3rem;
    gap: 20px;
    .option-boll {
      position: relative;
      display: flex;
      // align-items: center;
      padding-left: 0.75rem;
      &::after {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        content: '';
        // position: absolute;
        left: 0px;
      }
      &.first::after {
        background-color: #24427a;
      }
      &.second::after {
        background-color: #f99b2e;
      }
      &.third::after {
        background-color: #5890c7;
      }
      &.fourth::after {
        background-color: #624aef;
      }
      &.fifth::after {
        background-color: #2c8a3e;
      }
      &.sixth::after {
        background-color: #ff97d6;
      }
      &.seventh::after {
        background-color: #00deb5;
      }
      &.eighth::after {
        background-color: #eb440f;
      }
      &.etc::after {
        background-color: #bcbbdc;
      }
      &.ul::after {
        background-color: rgb(249, 65, 68);
      }
      &.dl::after {
        background-color: rgb(45, 156, 219);
      }
    }
    .optin-arrow {
      &.navy {
        stroke: #24427a;
      }
      &.orange {
        background-color: #f99b2e;
      }
      &.blue {
        background-color: #5890c7;
      }
      &.purple {
        background-color: #624aef;
      }
      &.green {
        background-color: #2c8a3e;
      }
      &.gray {
        background-color: #bcbbdc;
      }
    }
    .oplabel-flex {
      display: flex;
      gap: 25px;
      align-items: center;
      .form-label {
        flex: 0 0 140px;
        margin: 0;
        font-size: 0.875rem;
      }
      .list-group-item-custom {
        max-width: 120px;
        display: flex;
        align-items: center;
        padding-left: 6px;
        padding-right: 6px;
        .item-text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 12px;
        }
      }
    }
  }
  .grid-section {
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    grid-template-columns: 65% 35%;
    align-items: flex-start;
    // gap: 50px;

    .bar-section {
      padding-right: 25px;
      .flex-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
      }
      .bar-height {
        height: 300px;
        width: 100%;
        display: flex;
      }
    }
    .pie-section {
      padding-left: 25px;
      .flex-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
      }
      .pie-height {
        width: 100%;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .line-section {
    margin-top: 3rem;
    h5 {
      margin-bottom: 0;
    }
    .flex-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
  }
  .aptable {
    table th {
      font-size: 1rem;
    }
    .flex-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
    .bd-right {
      border-right: 1px solid #e0e0e0;
    }
    .bd-bottom {
      border-bottom: 1px solid #e0e0e0;
    }
    .bd-left {
      border-left: 1px solid #e0e0e0;
    }
    .center-text {
      text-align: center;
    }
    .right-text {
      text-align: center;
    }
  }
  .doughnut-text {
    position: absolute;
    text-align: center;
    width: 140px;
    right: 80px;
    bottom: 115px;
  }
}
//----------------------------------
//----------------------------------
//유동인구 분석통계
#datalab-pop {
  h5 {
    margin-bottom: 0;
  }
  .title-datalab {
    border-bottom: 1px solid #8b8b8b;
    margin-bottom: 1rem;
    h5 {
      margin-bottom: 0.5rem;
      font-weight: bold;
    }
  }
  .form-flex {
    display: flex;
    align-items: center;
    gap: 50px;
    .form-group {
      margin-top: 0;
      .form-label {
        flex: 0 0 80px;
      }
    }
  }
  .btn-center {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
  .btn-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 1.5rem;
    .xlsx {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 21px;
      height: 21px;
    }
  }
  .td-right {
    text-align: right;
  }
  //유동인구 센서지도위치 라벨
  .title-labels {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
    gap: 20px;
    .option-boll {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 0.75rem;
      &::after {
        width: 14px;
        height: 14px;
        border-radius: 20px;
        content: '';
        position: absolute;
        left: 13px;
      }
      &.first::after {
        background-color: #24427a;
      }
      &.second::after {
        background-color: #f99b2e;
      }
      &.third::after {
        background-color: #5890c7;
      }
      &.fourth::after {
        background-color: #624aef;
      }
      &.fifth::after {
        background-color: #2c8a3e;
      }
      &.sixth::after {
        background-color: #ff97d6;
      }
      &.seventh::after {
        background-color: #00deb5;
      }
      &.eighth::after {
        background-color: #eb440f;
      }
      &.etc::after {
        background-color: #bcbbdc;
      }
    }
    .oplabel-flex {
      display: flex;
      gap: 25px;
      align-items: center;
      .form-label {
        margin: 0;
        font-size: 0.875rem;
      }
    }
  }
  .map-div {
    .flex-title {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
    }
    .map-box {
      width: 100%;
      height: 300px;
      background-color: #525252;
    }
  }
  .line-section {
    margin-top: 3rem;
    .flex-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
  }
  .aptable {
    table th {
      font-size: 1rem;
    }
    .flex-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
    .bd-right {
      border-right: 1px solid #e0e0e0;
    }
    .bd-bottom {
      border-bottom: 1px solid #e0e0e0;
    }
    .bd-left {
      border-left: 1px solid #e0e0e0;
    }
    .center-text {
      text-align: center;
    }
    .right-text {
      text-align: right;
    }
  }
}
//----------------------------------
//----------------------------------
//홍보노출수
#datalab-prmsta {
  .btn-center {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
  .btn-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 1.5rem;
    .xlsx {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 21px;
      height: 21px;
    }
  }
  .td-right {
    text-align: right;
  }
  //홍보영역별 노출수 비율 라벨
  .title-labels {
    display: flex;
    align-items: center;
    justify-content: center;
    .option-boll {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 0.75rem;
      &::after {
        width: 14px;
        height: 14px;
        border-radius: 20px;
        content: '';
        position: absolute;
        left: 13px;
      }
      &.first::after {
        background-color: #24427a;
      }
      &.second::after {
        background-color: #f99b2e;
      }
      &.third::after {
        background-color: #5890c7;
      }
      &.fourth::after {
        background-color: #624aef;
      }
    }
    .oplabel-flex {
      display: flex;
      gap: 25px;
      align-items: center;
      .form-label {
        margin: 0;
        font-size: 0.875rem;
      }
    }
  }
  .grid-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
    h5 {
      margin-bottom: 0;
    }
    .bar-section {
      .flex-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
      }
      .bar-height {
        height: 300px;
        width: 100%;
        display: flex;
      }
      // 홍보영역별 노출수 집계현황 label
      .title-labels {
        display: flex;
        align-items: center;
        justify-content: center;
        .option-boll {
          position: relative;
          display: flex;
          align-items: center;
          padding-left: 0.75rem;
          &::after {
            width: 14px;
            height: 14px;
            border-radius: 0;
            content: '';
            position: absolute;
            left: 13px;
          }
          &.first::after {
            background-color: #ee537c;
          }
          &.second::after {
            background-color: #eb927b;
          }
        }
        .oplabel-flex {
          display: flex;
          gap: 25px;
          align-items: center;
          .form-label {
            flex: 0 0 140px;
            margin: 0;
            font-size: 0.875rem;
          }
        }
      }
    }
    .pie-section {
      .flex-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
      }
      .pie-height {
        width: 100%;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .prmtable {
    h5 {
      margin-bottom: 0;
    }
    .flex-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .prm-flex {
        display: flex;
        align-items: center;
        gap: 15px;
        .prm-title-color {
          color: #eb6200;
          padding-left: 5px;
        }
        .prm-total-cnt-color {
          color: #da1e28;
          padding-left: 5px;
        }
        p {
          font-weight: bold;
        }
      }
    }
    table th {
      font-size: 1rem;
    }
    .td-center {
      text-align: center;
    }
  }
}
//----------------------------------
//----------------------------------
// 홍보 통계
#datalab-esg {
  .btn-center {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
  .btn-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 1.5rem;
    .xlsx {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 21px;
      height: 21px;
    }
  }
  .td-right {
    text-align: right;
  }
  //홍보영역별 노출수 비율 label
  .title-labels {
    display: flex;
    align-items: center;
    justify-content: center;
    .option-boll {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 0.75rem;
      &::after {
        width: 14px;
        height: 14px;
        border-radius: 20px;
        content: '';
        position: absolute;
        left: 13px;
      }
      &.first::after {
        background-color: #24427a;
      }
      &.second::after {
        background-color: #f99b2e;
      }
      &.third::after {
        background-color: #5890c7;
      }
      &.fourth::after {
        background-color: #624aef;
      }
    }
    .oplabel-flex {
      display: flex;
      gap: 25px;
      align-items: center;
      .form-label {
        flex: 0 0 140px;
        margin: 0;
        font-size: 0.875rem;
      }
    }
  }

  .grid-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 2fr));
    gap: 20px;
    h5 {
      margin-bottom: 0;
    }
    .bar-section {
      .flex-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
      }
      .bar-height {
        height: 300px;
        width: 100%;
        display: flex;
      }
      // 홍보영역별 노출수 집계현황,홍보노출진척현황 label
      .title-labels {
        display: flex;
        align-items: center;
        justify-content: center;
        .option-boll {
          position: relative;
          display: flex;
          align-items: center;
          padding-left: 0.75rem;
          &::after {
            width: 14px;
            height: 14px;
            border-radius: 0;
            content: '';
            position: absolute;
            left: 13px;
          }
          &.first::after {
            background-color: #ee537c;
          }
          &.second::after {
            background-color: #eb927b;
          }
        }
        .oplabel-flex {
          display: flex;
          gap: 25px;
          align-items: center;
          .form-label {
            flex: 0 0 140px;
            margin: 0;
            font-size: 0.875rem;
          }
        }
      }
    }
    .pie-section {
      .flex-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
      }
      .pie-height {
        width: 100%;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    //스케줄링 홍보효과 라벨
    .schdule {
      .title-labels {
        display: flex;
        align-items: center;
        justify-content: center;
        .option-boll {
          position: relative;
          display: flex;
          align-items: center;
          padding-left: 0.75rem;
          &::after {
            width: 14px;
            height: 14px;
            border-radius: 20px;
            content: '';
            position: absolute;
            left: 13px;
          }
          &.first::after {
            background-color: #00974e;
          }
          &.second::after {
            background-color: #ff8541;
          }
          &.third::after {
            background-color: #305399;
          }
        }
        .oplabel-flex {
          display: flex;
          gap: 25px;
          align-items: center;
          .form-label {
            flex: 0 0 140px;
            margin: 0;
            font-size: 0.875rem;
          }
        }
      }
    }
  }
}
//----------------------------------
//----------------------------------
//과금 모니터링
#datalab-monitor {
  .grid-article {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 1rem;
    .total-box {
      max-width: 500px;
      min-width: 200px;
      .total-p {
        font-weight: 400;
        margin-bottom: 1rem;
      }
      .total-money {
        font-weight: bold;
        float: right;
      }
    }
  }
  h5 {
    margin-bottom: 0;
  }
  .btn-center {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
  .btn-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 1.5rem;
    .xlsx {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 21px;
      height: 21px;
    }
  }
  .td-right {
    text-align: right;
  }
  //광고비별 과금 비율 라벨
  .title-labels {
    display: flex;
    align-items: center;
    justify-content: center;
    .option-boll {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 0.75rem;
      &::after {
        width: 14px;
        height: 14px;
        border-radius: 20px;
        content: '';
        position: absolute;
        left: 13px;
      }
      &.first::after {
        background-color: #ee537c;
      }
      &.second::after {
        background-color: #eb927b;
      }
      &.third::after {
        background-color: #fcc729;
      }
    }
    .oplabel-flex {
      display: flex;
      gap: 25px;
      align-items: center;
      .form-label {
        flex: 0 0 140px;
        margin: 0;
        font-size: 0.875rem;
      }
    }
  }
  .grid-table {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
  }
  .grid-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    align-items: flex-start;
    gap: 20px;
    .bar-section {
      .flex-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
      }
      .bar-height {
        height: 300px;
        width: auto;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }
    }
    .pie-section {
      .flex-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
      }
      .pie-height {
        width: auto;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .prmtable {
    .flex-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //테이블 건수통계 및 데이터 컬러라벨
      .prm-flex {
        display: flex;
        align-items: center;
        gap: 15px;
        .prm-title-color {
          padding-left: 5px;
          color: #000000;
          &.total {
            color: #da1e28;
          }
          &.ESG {
            color: #00974e;
          }
          &.smbusiness {
            color: #ff8541;
          }
          &.local {
            color: #305399;
          }
          &.bd-right {
            border-right: 1px solid #e0e0e0;
            padding: 0 0.5rem;
          }
        }
        .bd-none {
          border: 0;
        }
        p {
          font-weight: bold;
          font-size: 0.875rem;
        }
      }
    }
    .table th,
    td {
      font-size: 1rem;
    }
    .td-center {
      text-align: center;
    }
    .totalcount {
      text-align: center;
      font-weight: bold;
    }
  }
}
//----------------------------------
//----------------------------------
//ESG 스케줄링/노출추이
#datalab-esgschedule {
  .btn-center {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
  .btn-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 1.5rem;
  }
  //스케줄링 대비 노출 비율 라벨
  .title-labels {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    .option-boll {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 0.75rem;
      &::after {
        width: 14px;
        height: 14px;
        border-radius: 20px;
        content: '';
        position: absolute;
        left: 13px;
      }
      &.first::after {
        background-color: #00974e;
      }
      &.second::after {
        background-color: #ff8541;
      }
      &.third::after {
        background-color: #305399;
      }
    }
    .oplabel-flex {
      display: flex;
      gap: 25px;
      align-items: center;
      .form-label {
        flex: 0 0 140px;
        margin: 0;
        font-size: 0.875rem;
      }
    }
  }
  .chart-label {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    max-width: 100px;
    height: 22px;
    background-color: #393939;
    padding: 0 0.5rem;
    border-radius: 4px;
    right: 25px;
    top: 17px;
    .chart-boll {
      display: flex;
      position: relative;
      align-items: center;
      padding-left: 1rem;
      &::after {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        content: '';
        position: absolute;
        left: 1px;
      }
      &.first::after {
        background-color: #00974e;
      }
    }
    .chart-text {
      color: #ffffff;
    }
  }
  .section-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    .pie-height {
      width: 100%;
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  h5 {
    margin-bottom: 0;
  }
}

#bsperModal {
  max-width: 615px;
  height: auto;
  overflow: auto;
  .map-box {
    height: 300px;
    background-color: #6f6f6f;
  }
  .sys-area {
    margin-bottom: 1rem;
    .form-control.mw-280 {
      max-width: 100%;
    }
    .form-group {
      border-bottom: 1px solid #e0e0e0;
      padding: 1rem 0px;
      margin: 0;
      .input-group
        > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
          .valid-feedback
        ):not(.invalid-tooltip):not(.invalid-feedback) {
        min-width: 100px;
      }
      &.align-start {
        align-items: flex-start;
      }
      .form-label {
        flex: 0 0 110px;
      }
      .flex-div {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}
#blEditModal {
  max-width: 844px;
  height: auto;
  .modal-body {
    overflow: auto;
    .modal-table {
      td,
      th {
        text-align: center;
      }
    }
    .bd-ri {
      border-right: 1px solid #e0e0e0;
    }
    .bd-bt {
      border-bottom: 1px solid #e0e0e0;
    }

    .sys-area {
      margin-bottom: 1rem;
      .form-control.mw-280 {
        max-width: 100%;
      }
      .form-group {
        border-bottom: 1px solid #e0e0e0;
        padding: 1rem 0px;
        margin: 0;
        .input-group
          > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
            .valid-feedback
          ):not(.invalid-tooltip):not(.invalid-feedback) {
          min-width: 100px;
        }
        &.align-start {
          align-items: flex-start;
        }
        .form-label {
          flex: 0 0 110px;
        }
        .flex-div {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }
}
#apModal {
  max-width: 615px;
  height: auto;
  overflow: auto;
  .map-box {
    height: 300px;
    background-color: #6f6f6f;
  }
  .btn-map-unit-state {
    width: 24px;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: 0;
    background: url('../images/icon/wifi.png') no-repeat center;
  }
  .sys-area {
    margin-bottom: 1rem;
    .form-control.mw-280 {
      max-width: 100%;
    }
    .form-group {
      border-bottom: 1px solid #e0e0e0;
      padding: 1rem 0px;
      margin: 0;
      .input-group
        > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
          .valid-feedback
        ):not(.invalid-tooltip):not(.invalid-feedback) {
        min-width: 100px;
      }
      &.align-start {
        align-items: flex-start;
      }
      .form-label {
        flex: 0 0 110px;
      }
      .flex-gap {
        display: flex;
        gap: 15px;
      }
      .password-box {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
      }
      .align-label {
        display: flex;
        align-self: flex-start;
      }
      .position-rel {
        position: relative;
      }
      .cancel-position {
        position: absolute;
        left: -40px;
        top: 10px;
      }
      .icon-btn {
        min-width: 0;
        width: 0;
      }
      .attatch-list {
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;
          .btn-icon {
            color: var(--bs-gray);
          }
          .ratio {
            width: 86px;
            margin: 0 1rem;
          }
          .flex-start {
            flex: 1;
            p {
              text-overflow: ellipsis;
              white-space: nowrap;
              word-wrap: normal;
              overflow: hidden;
              width: inherit;
              width: 80%;
            }
          }
        }
      }
    }
  }
}
#couponModal {
  max-width: 475px;
  .btn-area {
    margin-top: 2rem;
    margin-bottom: 0 !important;
    &.jusify-end {
      display: flex;
      justify-content: flex-end;
    }
  }
}
// HOVER
@media (min-width: 1025px) {
  @media (hover: hover) {
    #modal-map-list {
      .modal-content {
        .modal-body {
          .btn-group {
            label.btn-list {
              &:hover {
                background: rgba(var(--bs-primary-rgb), 0.12) !important;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  #prm-schedule .caln-box .list .b-word {
    max-width: 120px;
  }
  #prm-schedule .seemore {
    margin-top: 0.25rem;
  }
  #prm-schedule .title-color {
    padding-right: 0.5rem;
  }
  #prm-schedule .grid-calender {
    margin-left: 0;
  }
  .doughnut-text {
    right: 116px;
  }
}

// 적용 후 삭제
.chart-area {
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid var(--bs-border);
  // background: var(--bs-gray-100);
}

.custom-map-breadcrumb {
  position: absolute;
  top: 1.5rem;
  left: 1rem;
  padding: 0.25rem 1rem;
  background: var(--bs-white);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  li {
    font-size: 1rem;
    font-weight: 700;
    color: var(--bs-dark);
    padding-left: 2.5rem;
    background: url('../images/icon/arrow_right.png') no-repeat 0.5rem center;
    &:first-of-type {
      padding-left: 0;
      background: none;
    }
  }
}

.btn-custom-map-unit {
  border-color: var(--bs-info);
  background: var(--bs-white);
  color: var(--bs-dark);
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  margin: 0 2px;
  white-space: nowrap;
  span {
    transition: 0.15s ease-in-out;
    color: var(--bs-primary);
    padding-left: 0.5rem;
  }
  &:focus,
  &:hover,
  &:active {
    border-color: var(--bs-primary-dark);
    background: var(--bs-primary-dark);
    color: var(--bs-white);
    span {
      color: var(--bs-white);
    }
  }
}
.btn-custom-map-unit-select {
  border-color: var(--bs-primary-dark);
  background: var(--bs-primary-dark);
  color: var(--bs-white);
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  margin: 0 2px;
  white-space: nowrap;
  // span {
  //   transition: 0.15s ease-in-out;
  //   color: var(--bs-white);
  //   padding-left: 0.5rem;
  // }
  &:focus,
  &:hover,
  &:active {
    border-color: var(--bs-primary-dark);
    background: var(--bs-primary-dark);
    color: var(--bs-white);
    span {
      color: var(--bs-white);
    }
  }
}

.fc-daygrid-day-frame {
  min-height: 100% !important;
  height: 200px !important;
  position: relative !important;
}

.fc-daygrid-day-top {
  min-height: 200px !important;
}
