/* ArrowMain */
.arrow-container {
  /* display: inline-flex;
  align-items: center;
  position: relative; */
}

.arrow-container {
  transform-origin: -11% 39%;
  padding-left: 20px;
}

.arrow-container svg::before {
  content: '500';
}

.rotate-arrow::before {
  content: '500';
  position: absolute;
}

.number-circle {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute;
  left: 55px; */
  z-index: 999;
  border-radius: 50%;
  display: none;
}

.number {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  font-weight: 900;
}

.opacity {
  background-color: rgba(255, 255, 255, 0.5);
}
/* common */

.yellow {
  background-color: #ffc700;
}

.blue {
  background-color: #0087e8;
}

.orange {
  background-color: #f97b34;
}

.red {
  background-color: #ff0000;
}
