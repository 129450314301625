/* ArrowMain */
.arrow-container {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.number-circle {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 55px;
  z-index: 999;
  border-radius: 50%;
}

.number {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  font-weight: 900;
}

.opacity {
  background-color: rgba(255, 255, 255, 0.5);
}
/* common */

.yellow {
  background-color: #ffc700;
}

.blue {
  background-color: #0087e8;
}

.orange {
  background-color: #f97b34;
}

.red {
  background-color: #ff0000;
}

/* ArrowThree */
.animation-arrows {
  display: inline-flex;
  transform-origin: 60% 50%;
}

.animation-arrows:last-child::after {
  content: '-------------------------';
  color: transparent;
}

.ani {
  animation: gradation-arrow 2s infinite;
}

.table-arrow {
  transform: rotate(180deg);
}

.table-arrow:nth-child(2) {
  opacity: 0.5;
}

.table-arrow:nth-child(1) {
  opacity: 0.2;
}

.ani:nth-child(1) {
  animation-delay: 0.3s;
}

.ani:nth-child(2) {
  animation-delay: 0.15s;
}

@-webkit-keyframes gradation-arrow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes gradation-arrow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
