/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
} */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

:root {
  --box-color-transparent: rgba(0, 16, 47, 0.8);
  --white: #fff;
  --gray: #c6c6c6;
  --red: #dc3545;
  --primary: #0070d7;
  --border-radius: 20px;
}

.board-inner {
  width: 100%;
  max-width: 100%;
  padding: 0 2rem;
  margin: 0 auto;
  height: 100%;
}

.title {
  font-weight: 500;
  font-size: 1.15rem;
  color: var(--white);
}

.dashboard-main {
  width: 100%;
  /* height: 100%; */
}

.map-hidden {
  overflow: hidden;
}

.prevent-user-select {
  user-select: none;
}

.cursor-pointer {
  cursor: pointer;
}

.dashboard-header {
  width: 100%;
  padding: 1rem;
  margin-top: 3.5rem;
}

.dashboard-header .board-inner {
  display: flex;
  justify-content: flex-end;
}

.dashboard-header .board-inner > * {
  border: none;
  padding: 0.7rem 1rem;
  background-color: var(--box-color-transparent);
  border-radius: 28px;
  color: var(--gray);
  cursor: pointer;
  position: relative;
  z-index: 99;
  font-size: 0.8rem;
}

.dashboard-header .board-inner .btn-full-view,
.dashboard-header .board-inner .btn-default-view {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 0.7rem;
}

.dashboard-header .board-inner .btn-full-view.no-show,
.dashboard-header .board-inner .btn-default-view.no-show {
  display: none;
}

.dashboard-header .board-inner .btn-full-view i,
.dashboard-header .board-inner .btn-default-view i {
  width: 20px;
  height: 20px;
  display: inline-flex;
  background-position: center;
}

/* header-end */

.dashboard-flex-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.dashboard-flex-container > * {
  width: 50%;
}

.left-container .dashboard-title {
  font-size: 5.2rem;
  color: var(--primary);
  position: absolute;
  z-index: 99;
  line-height: 1;
}

.left-container .dashboard-title span {
  color: rgba(101, 101, 101, 0.8);
}

/* .left-container .clock {
  font-size: 1.7rem;
  color: rgba(101, 101, 101, 0.8);
  font-weight: 800;
  position: relative;
  top: -30px;
  z-index: 99;
} */

.left-container .info-box {
  display: flex;
  z-index: 99;
  height: 50px;
}

.left-container .clock-box {
  display: flex;
  align-items: center;
  width: 340px;
}

.left-container .clock {
  font-size: 1.7rem;
  color: rgba(101, 101, 101, 0.8);
  font-weight: 800;
}

.left-container .weather-box {
  display: flex;
  align-items: center;
}
.left-container .weather {
  margin: auto;
}

.left-nav-container {
  position: absolute;
  top: 37%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.left-container .accordian-menus {
  padding: 1rem;
  background-color: var(--box-color-transparent);
  border-radius: var(--border-radius);
  flex-grow: 0;
  align-self: flex-start;
  /* position: absolute; */
  z-index: 99;
  /* top: 50%;
  transform: translateY(-50%); */
}

.left-container .accordian-menus .accordian-btn {
  padding: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  border: 0;
  position: relative;
  background-color: transparent;
  color: white;
  cursor: pointer;
  font-size: 1.1rem;
}

.left-container .left-bottom-container {
  max-width: 750px;
  width: 40%;
  display: flex;
  gap: 1rem;
  position: absolute;
  bottom: 4rem;
  z-index: 99;
}

.left-bottom-container > * {
  /* width: 26%; */
  padding: 1rem;
  background-color: salmon;
  border-radius: var(--border-radius);
  background-color: var(--box-color-transparent);
  color: var(--white);
}

.bottom-container-left {
  width: 80%;
}

.left-bottom-container .sensor-situation {
  margin-top: 1.5rem;
}

.sensor-situation > *:not(.divide-line) {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: 0 auto;
}

.sensor-situation .divide-line {
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.8);
  margin: 0.7rem auto;
}

.sensor-situation .sensor-title {
  font-weight: 800;
  font-size: 1.5rem;
}

.sensor-situation .sensor-title .normal {
  color: var(--primary);
}

.sensor-situation .sensor-title .error {
  color: var(--red);
}

.sensor-situation .sensor-num {
  font-weight: 600;
  font-size: 2.8rem;
}

/* left-bottom-container-end */
.bottom-container-right {
  width: 100%;
}

.bottom-container-right .visitor-total-count {
  width: 100%;
  margin-top: 2.4rem;
  font-weight: 800;
  font-size: 3rem;
}

.bottom-container-right .visitorcount-period {
  font-size: 1.25rem;
}

/* --------left-container-end-------- */

.right-container > .board-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.right-container .join-area {
  display: flex;
  gap: 1rem;
  background-color: var(--box-color-transparent);
  padding: 0.5rem 1rem;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: var(--white);
  font-size: 0.8rem;
  position: relative;
  z-index: 99;
}

.right-container .join-area > * {
  display: flex;
  align-items: center;
}

.join::before {
  content: '';
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-color: var(--primary);
  border-radius: 1rem;
  top: calc(50% - 0.5rem);
  margin-right: 0.25rem;
}

.no-join::before {
  content: '';
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-color: var(--red);
  border-radius: 1rem;
  top: calc(50% - 0.5rem);
  margin-right: 0.25rem;
}

.population-situation {
  position: relative;
  z-index: 99;
  padding: 1.25rem 1rem;
  background-color: var(--box-color-transparent);
  margin-top: 1rem;
  width: 35%;
  border-radius: var(--border-radius);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
}

.population-situation .status {
  display: flex;
  justify-content: space-between;
}

.population-situation h3 {
  font-size: 4rem;
  color: #2cf2ff;
  margin-top: 1rem;
  line-height: 1;
}

.population-situation h3 span {
  font-size: 2rem;
}

.population-situation .ddl {
  font-size: 1rem;
  padding: 0.2rem 0.5rem;
  border-radius: 0.25rem;
}

.right-container .today-graph,
.right-container .top-graph {
  width: 49%;
  height: 300px;
  margin-top: 1.9rem;
  padding: 1rem;
  position: relative;
  background-color: var(--box-color-transparent);
  border-radius: var(--border-radius);
}

.right-container .today-graph {
  position: absolute;
  width: 26%;
  bottom: 24rem;
}

.right-container .top-graph {
  width: 36%;
  position: absolute;
  bottom: 4rem;
}

/* select-box */
.population-situation .css-a79coy-control {
  background-color: rgba(255, 255, 255, 0.9);
}

.population-situation .css-dqmvnp-ValueContainer {
  padding: 0.4rem 0.6rem;
}

.population-situation .css-espriu-menu {
  font-size: 1rem;
}

/* 새로추가한 css 23.07.19 */
.direction-container {
  /* position: absolute; */
  z-index: 9999;
  /* top: 60%; */
  background-color: var(--box-color-transparent);
}

/* 토스트 알림 24.02.02 */
.msg-container {
  display: flex;
  space-between: center;
}

.toast-ap-level {
  font-size: 1.5rem;
  margin-right: 1.5rem;
}

.toast-ap-name {
  font-weight: bolder;
  font-size: 1.5rem;
  margin-right: 1.5rem;
}

.toast-ap-count {
  font-size: 1.5rem;
}

.Toastify__toast-container {
  width: calc(50%) !important;
}

.toast-color-level-danger {
  --danger: #f94144;
  background: var(--danger) !important;
  color: #fff !important;
}
