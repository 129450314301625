.custom-marker {
  box-sizing: border-box;
  /* position: relative; */
  min-width: 93px;
  width: 100%;
  height: 52px;
  background-color: #195078;
  border-radius: 10px;
  /* background: linear-gradient(to bottom, #2787cc, #195078); */
  background-color: #142286;
}

.custom-marker::after {
  content: '';
  position: absolute;
  right: 50%;
  top: 70%;
  margin-top: 15px;
  /* border-top: 7px solid #195078; */
  border-top: 7px solid #142286;
  border-right: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid transparent;
}
.custom-marker.nosign::after {
  content: '';
  position: absolute;
  right: 50%;
  top: 70%;
  margin-top: 15px;
  /* border-top: 7px solid #195078; */
  border-top: 7px solid rgba(80, 80, 80, 0.5);
  border-right: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid transparent;
}

.custom-marker.pink::after {
  content: '';
  position: absolute;
  right: 50%;
  top: 70%;
  margin-top: 15px;
  /* border-top: 7px solid #ff2564; */
  border-top: 7px solid #f6960a;
  border-right: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid transparent;
}

.custom-marker.pink .custom-marker-value {
  color: #002060;
}

.custom-marker.pink .custom-marker-title {
  color: #002060;
}

.custom-marker.wide {
  background: linear-gradient(to bottom, #12daab, #067d68);
}

.custom-marker.wide::after {
  border-top: 7px solid #067d68;
}

.custom-marker.wide .custom-marker-value {
  color: #e7ff22;
}

.custom-marker-title {
  grid-column: 1 / 5;
  grid-row: 1 / 2;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: xx-small;
}

.custom-marker-title-nosign {
  grid-column: 1 / 5;
  grid-row: 1 / 2;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: xx-small;
}

.custom-marker-icon {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  padding-left: 10px;
  margin-top: -5px;
}

.custom-marker-icon-center {
  margin-bottom: -5px;
  place-items: center;
  display: grid;
}

.custom-marker-value {
  grid-column: 2/5;
  grid-row: 2 / 3;
  padding-top: 7px;
  margin-left: 5px;
  margin-right: 10px;
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
}

.custom-circle-marker {
  background-color: #0070d7;
  height: 82px;
  width: 82px;
  border: solid 1px #9f9f9f;
  /* border-radius: 50%; */
  border-radius: 10px;
  font-size: xx-large;
  font-weight: bolder;
  color: #fff;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}
/* .custom-circle-marker-text {
  margin: auto;
} */

.custom-circle-market-text-2 {
  font-size: 1.125rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  padding: 4px 0;
  font-weight: 500;
}
